import { Cart, CartItem, LineItemService } from '@congacommerce/ecommerce';
import _, { isEmpty, startCase } from 'lodash';
import moment from 'moment';
import { parseDate } from 'ngx-bootstrap/chronos';
import { AtecoClassification6409 } from '../../modules/switch-in/technical-changes/enums/ateco_classification';
import { FornituraEsistente, OrderEntryState } from '../../store/models/order-entry-state';
import {
    CompanyIdentity,
    DatiAnagraficiMBV2,
    DiscountsPromoPrices,
    OrderEntryState_v2,
    PaymentInfo,
    Product,
    StateAddress,
} from '../../store/models/order-entry-state_v2';
import { ExistingMastershipType, MastershipType, UserState } from '../../store/models/user-state';
import { Regex } from '../config/regex';
import { AptAddressType } from '../enums/apttus/apt-address-type';
import { AptCommodityType, APT_COMMODITY_TYPE_MAP } from '../enums/apttus/apt-commodity-typeof-sale';
import { AptCustomerType } from '../enums/apttus/apt-customer-type';
import { AptLineStatus } from '../enums/apttus/apt-line-status';
import { AptContactRole } from '../enums/apttus/apt-mb-representative';
import { PowerPhase } from '../enums/apttus/apt-power-phase';
import { AptPowerVoltage } from '../enums/apttus/apt-power-voltage';
import { AptProductFamily } from '../enums/apttus/apt-product-family';
import { AptProductGroups } from '../enums/apttus/apt-product-groups';
import { AptProductType } from '../enums/apttus/apt-product-type';
import { D365AccountMigrated } from '../enums/d365/d365-account-migrated';
import { D365CustomerSegment } from '../enums/d365/d365-customer-segment';
import { CustomerType } from '../enums/shared/customer-type';
import { DestinationUse, SupplyUse, SupplyUsePreventivo } from '../enums/shared/destination-use.enum';
import { GasMeterClass } from '../enums/shared/gas-meter-class';
import { NumberOfEmployees, Revenue } from '../enums/shared/market-profiling.enum';
import { PrivacyFlag } from '../enums/shared/privacy-flag';
import { SubProductType } from '../enums/shared/sub-product-type';
import {
    GasConsumptionUse,
    GasConsumptionUseMB,
    GasTypeOfUse,
    PowerConsumptionUse,
    PowerTypeOfUse,
    TypeOfUse,
} from '../enums/shared/type-of-usage.type';
import { DeepPartial } from '../interfaces/deep-partial';
import { AnagraficaData } from '../models/app/recupera-dati-salesup.response';
import { EglCartExtended } from '../models/apttus/tables/cart/egl-cart-extended';
import { EglCartItemLight } from '../models/apttus/tables/cart/egl-cart-item-light';
import { EglCartPAVLight } from '../models/apttus/tables/pav/egl-cart-p-a-v-light';
import { EglProductExtended } from '../models/apttus/tables/product/egl-product-extended';
import { EglAssetLineItem } from '../models/apttus/tables/user/egl-asset-line-item';
import { D365Account, D365AddressRoleType, D365Asset, D365ContactType } from '../models/d365/d365-asset.interface';
import { eglAddress2Address, fornituraEsistente2Address } from './address.functions';
import {
    cleanObj,
    flagToBoolOrNull,
    getAvailablePower,
    isActivePackageProduct,
    isDiscountItem,
    isNotDiscountItem,
    isVisibleProduct,
    parsePhoneNumber,
    toLocaleDateString,
} from './misc.functions';
import { calculateNdsPrices } from './price.functions';
import {
    convertSegmentAptToD365,
    d365AccountMigratedToMastershipType,
    d365AccountMigratedToStrictMastershipType,
    getCivicAndSuffix,
    getNumberOrNull,
    mastershipTypeToD365AccountMigrated,
} from './remap.functions';
import { getCompleteCivic, istatCodeNormalizer } from './string-format.functions';
import { orderEntryV1ToV2 } from './transformation-v1-v2.functions';
import { orderEntryV2ToV1 } from './transformation-v2-v1.functions';
import {
    containsCommodity,
    containsProductComplex,
    containsProductGas,
    containsProductMaintenance,
    containsProductPower,
    containsProductSmartHome,
    isApplianceInsurance,
    isImplantInsurance,
    isOrderEntryStateV2,
    isProductRcDanni,
    isSalesProcessAllowedDeferredSale,
    ProductTypeInput,
} from './verifications.functions';

export type D365AccountContactData = Omit<D365Account, 'addresses' | 'contacts'> & {
    addresses: AddressTypeMap;
    contact: D365Account['contacts'][D365ContactType] & { contactRole: D365ContactType };
};

export type AddressTypeMap = { [addressTypeRole in D365AddressRoleType]?: StateAddress };

export const eglAssetLineItem2Product = (assetLineItem: Partial<EglAssetLineItem>): DeepPartial<Product> => {
    const {
        AttributeValue,
        egl_classification_64_09,
        egl_pdf,
        egl_POD_PDR,
        egl_Product_Family,
        egl_service_address,
        egl_supply_code: supplyCode,
        Id,
        Location,
        Product,
        ProductId,
        ProductType,
    } = assetLineItem || ({} as Partial<EglAssetLineItem>);
    // Attenzione! moment(undefined) ha lo stesso funzionamento di moment() [restituisce now()]
    // moment('') restituisce "Invalid Date"
    // TODO: controllare nel resto del codice se now() sia accettabile come risultato di moment(undefined)
    const effectiveDate = moment(assetLineItem.EffectiveDate || '');
    const startDate = moment(assetLineItem.StartDate || '');
    const originalStartDate = moment(assetLineItem.OriginalStartDate || '');
    const powerOrGas = aptProductType2AptCommodityType(ProductType, egl_POD_PDR);
    return {
        assetId: Id,
        productId: ProductId,
        podPdr: egl_POD_PDR,
        pdf: egl_pdf,
        powerOrGas,
        supplyCode,
        productType: Product?.ProductType as AptProductType,
        family: egl_Product_Family || (Product?.Family as AptProductFamily),
        deliveryAddress: eglAddress2Address(egl_service_address),
        addressType: egl_POD_PDR && powerOrGas ? AptAddressType.Fornitura : AptAddressType.Spedizione,
        businessDetails: {
            ateco: AttributeValue?.egl_Customer_ATECO_Code__c,
            activity: AttributeValue?.egl_commercial_branch__c,
            sector: AttributeValue?.egl_Commodity_Sector__c,
            class6409: egl_classification_64_09 as AtecoClassification6409, // Chiave Classificazione 6409
        },
        technicalDetails: {
            typeOfUsage: getValidTypeOfUse(AttributeValue?.egl_typeofusage__c, powerOrGas),
            cityGate: AttributeValue?.egl_citygate__c,
            ...(powerOrGas === AptCommodityType.Gas
                ? {
                      // matricola correttore
                      gasMeterAdjustmentSerialNumber: AttributeValue?.egl_serial_number_corrector__c,
                      // cifre misuratore
                      gasMeterDigits: AttributeValue?.egl_digits_meter__c,
                      // classe misuratore
                      gasMeterClass: AttributeValue?.egl_meter_class__c as GasMeterClass,
                      gasMeterEmbeddedCluster: AttributeValue?.egl_meter_integrated__c,
                      // matricola contatore
                      meterSerialNumber: AttributeValue?.egl_pdr_serial_number__c,
                      // Cifre correttore
                      gasMeterAdjustmentDigit: AttributeValue?.egl_digits_corrective__c,
                  }
                : powerOrGas === AptCommodityType.Power
                  ? {
                        pwrVoltage: AttributeValue?.egl_voltage__c as AptPowerVoltage,
                        pwrInstantaneousPower:
                            AttributeValue?.egl_Engaged_Power__c ||
                            getNumberOrNull(AttributeValue?.egl_Required_Power__c),
                        pwrAvailablePower:
                            getNumberOrNull(AttributeValue?.egl_Available_Power__c) ||
                            getAvailablePower(getNumberOrNull(AttributeValue?.egl_Required_Power__c)),
                        pwrPowerPhases: AttributeValue?.egl_num_of_phases_available__c as PowerPhase,
                        needed: {
                            pwrInstantaneousPower: getNumberOrNull(AttributeValue?.egl_engaged_power_required__c),
                            pwrVoltage: AttributeValue?.egl_voltage_required__c as AptPowerVoltage,
                            pwrAvailablePower: getNumberOrNull(AttributeValue?.egl_available_power_required__c),
                            pwrPowerPhases: AttributeValue?.egl_num_of_phases_required__c as PowerPhase,
                        },
                    }
                  : {}),
        },
        paymentInfo: {
            paymentInstrument: AttributeValue?.egl_Payment_Instrument__c,
            paymentType: AttributeValue?.egl_Payment_Type__c,
            paymentTool: {
                billingPreferenceCode: Location?.egl_code,
                id: Location?.Id,
                iban: Location?.egl_billingaccount_iban,
                holder: {
                    vatCode: Location?.egl_bankaccount_holder_taxcode,
                    companyName: Location?.egl_bankaccount_holder_company_name,
                    fiscalCode: Location?.egl_bankaccount_holder_fiscalcode,
                    firstName: Location?.egl_bankaccount_holder_firstname,
                    lastName: Location?.egl_bankaccount_holder_lastname,
                },
                sepaSubscriber: {
                    fiscalCode: Location?.egl_subscriber_sepa_fiscalcode,
                    firstName: Location?.egl_subscriber_sepa_firstname,
                    lastName: Location?.egl_subscriber_sepa_lastname,
                },
                // bankAccountOwner: ,
                // revocationReason: ,
            },
        } as PaymentInfo,
        configurations: {
            // Da verificare
            supplyUse:
                getValidSupplyUse(AttributeValue?.['egl_supply_use__c']) ||
                getValidSupplyUse(AttributeValue?.egl_power_typesofusage__c),
        },
        ...(effectiveDate.isValid() && { effectiveDate: effectiveDate.toDate() }),
        ...(startDate.isValid() && { startDate: startDate.toDate() }),
        ...(originalStartDate.isValid() && { originalStartDate: originalStartDate.toDate() }),
    };
};

export const eglCart2Products = (cart: Cart): DeepPartial<Product>[] => {
    const itemsGroups = LineItemService.groupItems(cart.LineItems) as unknown as EglItemGroup<EglCartItemLight>[];
    return itemsGroups.map((item, idx) => {
        const product = eglCartLineItem2Product(item);
        return {
            ...product,
            idx,
            isAllowedDeferredSale:
                product?.isAllowedDeferredSale &&
                isSalesProcessAllowedDeferredSale((cart as EglCartExtended)?.egl_sales_process),
        };
    });
};

function pavToPoweOrGas(
    pav: EglCartPAVLight,
    family?: AptProductFamily,
    productType?: AptProductType,
): AptCommodityType {
    const FAMILY_MAP = {
        [AptProductFamily.Commodity]: 'egl_commodity_supplytype__c',
        [AptProductFamily.CommodityLegacy]: 'egl_commodity_supplytype__c',
        [AptProductFamily.Assicurazione]: 'egl_Insurance_Type__c',
    };
    return (pav || {})[FAMILY_MAP[family] || ''] || aptProductTypeToAptCommodityType(productType);
}

export const eglCartLineItem2Product = (item: EglItemGroup<EglCartItemLight>): DeepPartial<Product> => {
    const mainLine = item.MainLine;
    const product = mainLine?.Product;
    const pav = mainLine?.AttributeValue;
    const startDate = mainLine.StartDate && moment(mainLine.StartDate);

    try {
        const productState: DeepPartial<Product> = {
            productId: product?.Id,
            lineItemId: mainLine?.Id,
            productType: product?.ProductType,
            family: product?.Family,
            familyOps: product?.egl_Family_OPS,
            name: product?.Name,
            codeOrSku: product?.ProductCode || product?.egl_unique_product_code,
            addressType: product?.egl_address_type || AptAddressType.Fornitura,
            privacyRequired: product?.egl_privacy_required,
            lineItemStatus: mainLine?.LineStatus,
            discountsPromo: Array.from(
                new Set(
                    (item.Options || []).filter(isActivePackageProduct).map((item: EglCartItemLight) => {
                        const {
                            egl_Appliance_Brand__c,
                            egl_Appliance_type_01__c,
                            egl_product_firstname__c,
                            egl_product_lastname__c,
                            egl_Day_Of_Birth__c,
                        } = item?.AttributeValue || {};
                        //Gestione default
                        let promoName = item.Option?.Name;
                        // Gestione per NoPensieri Elettrodomestici
                        if (egl_Appliance_Brand__c) {
                            promoName = egl_Appliance_Brand__c;
                            egl_Appliance_type_01__c && (promoName = `${egl_Appliance_type_01__c} - ${promoName}`);
                        }
                        //Gestione per NoPensieri Salute
                        egl_product_firstname__c &&
                            egl_product_lastname__c &&
                            egl_Day_Of_Birth__c &&
                            (promoName = `${egl_product_firstname__c} ${egl_product_lastname__c} - ${toLocaleDateString(
                                egl_Day_Of_Birth__c,
                            )}`);
                        return promoName;
                    }),
                ),
            ),
            discountsPromoPrices: (item.Options || [])
                .filter((item: EglCartItemLight) => isActivePackageProduct(item) && isDiscountItem(item))
                .map((item: EglCartItemLight) => {
                    return {
                        promoName: item?.Option?.Name,
                        promoPrice: item?.PriceUom == 'Euro' ? item?.ListPrice : item?.egl_percentage_value,
                        discountType: item?.PriceUom,
                    } as unknown as DiscountsPromoPrices;
                }),
            brickPriceList: containsCommodity(product?.ProductType)
                ? 0
                : !_.isEmpty(item?.Options)
                  ? (item.Options || [])
                        .filter((item: EglCartItemLight) => isActivePackageProduct(item) && isNotDiscountItem(item))
                        .reduce((agg, { ListPrice, Quantity, AttributeValue }) => {
                            return agg + (AttributeValue?.egl_override_list_price__c || ListPrice) * (Quantity || 1);
                        }, 0)
                  : (item.MainLine as unknown as CartItem)?.PriceListItem?.ListPrice,
            brickList: (item.Options || [])
                .filter((item: EglCartItemLight) => isActivePackageProduct(item) && isNotDiscountItem(item))
                .map((item) => {
                    return {
                        brickName: item?.Option?.Name,
                        brickPrice: containsCommodity(product?.ProductType)
                            ? 0
                            : item?.AttributeValue?.egl_override_list_price__c || item?.ListPrice,
                        quantity: item?.Quantity,
                    };
                }, 0),
            isAllowedDeferredSale: mainLine?.LineStatus !== AptLineStatus.Cancelled && product?.egl_is_expired,
            productStatus: product?.Status,
            supplyCode: mainLine?.egl_supply_code,
            powerOrGas:
                APT_COMMODITY_TYPE_MAP[product?.APTSENI_Commodity] ||
                pavToPoweOrGas(pav, product?.Family, product?.ProductType),
            podPdr: pav?.egl_PDR__c || pav?.egl_POD__c || null,
            isCombinedSale: (pav?.egl_combined_sale_insurance__c || '').toUpperCase() !== 'NO' ? true : null,
            configurations: {
                greenOption: flagToBoolOrNull(pav?.egl_green_option__c),
                invoiceShippingMethod: pav?.egl_Invoice_Shipping_Method__c,
                relatedOrderNumber: pav?.egl_selling_order_number__c,
                partnership: {
                    selected: pav?.egl_partnership__c,
                },
                destinationUse: product.egl_is_power_for_outbuilding
                    ? DestinationUse.Appurtenances
                    : getValidDestinationUse(pav?.egl_power_typesofusage__c),
                // Da verificare
                supplyUse:
                    getValidSupplyUse(pav?.egl_supply_use__c) || getValidSupplyUse(pav?.egl_power_typesofusage__c),
                registrationTool: pav?.egl_using_registration_tool__c,
                potentialConsumption: pav?.egl_gas_potential_consumption__c,
                floor: pav?.egl_Floor__c,
                allowDigitalCommunications: flagToBoolOrNull(pav?.['egl_Privacy_Zurich_01__c']),
                installmentDuration: pav?.egl_installment_duration__c,
                billCharge: (pav?.egl_addebitoinbolletta__c || '').toUpperCase() === 'SI',
                quantity: mainLine.Quantity,
                vulnerabilityOver75: pav?.egl_vulnerabilitytype_over75__c,
                vulnerabilitySocialBonus: pav?.egl_vulnerabilitytype_socialbonus__c,
                vulnerabilitySaeMapre: pav?.egl_vulnerabilitytype_saemapre__c,
                vulnerabilityDisabled: pav?.egl_vulnerabilitytype_disabled__c,
                age: pav?.egl_Age_Object_Maintenance__c,
                brand: pav?.egl_Brand_Model_Object_Maintenance__c,
                centralizedSystem: pav?.egl_Centralized_System__c,
                hasWarranty: pav?.egl_Warranty_Object_Maintenance__c,
                heatingSystemFuel: pav?.egl_Heating_System_Fuel_Type__c,
                heatingSystemType: pav?.egl_Heating_System_type__c,
                installmentDate: pav?.egl_product_installation_date__c,
                typeWaterHeater: pav?.egl_Type_Water_Heater__c,
                productTaxDeduction: { taxDeductionId: pav?.egl_tax_detraction__c },
                creditCampaign: pav?.egl_consumer_credit_campaign__c || null,
                creditAssignmentAttr: pav?.egl_credit_assignment__c,
                usedGoodsInStock: pav?.egl_used_goods_in_stock__c,
                raee: pav?.egl_raee__c,
                relatedOffer: pav?.egl_related_offer__c,
                priceList: pav?.egl_pricelist__c,
            },
            technicalDetails: {
                pwrInstantaneousPower: getNumberOrNull(pav?.egl_Required_Power__c),
                pwrVoltage: pav?.egl_voltage__c as AptPowerVoltage,
                pwrAvailablePower:
                    getNumberOrNull(pav?.egl_Available_Power__c) ||
                    getAvailablePower(getNumberOrNull(pav?.egl_Required_Power__c)),
                pwrPowerPhases: pav?.egl_num_of_phases_available__c as PowerPhase,
                needed: {
                    pwrInstantaneousPower: getNumberOrNull(pav?.egl_engaged_power_required__c),
                    pwrVoltage: pav?.egl_voltage_required__c as AptPowerVoltage,
                    pwrAvailablePower: getNumberOrNull(pav?.egl_available_power_required__c),
                    pwrPowerPhases: pav?.egl_num_of_phases_required__c as PowerPhase,
                },
            },
            vendor: {
                name: pav?.egl_commodity_distributor__c,
            },
            paymentInfo: {
                paymentInstrument: pav?.egl_Payment_Instrument__c,
                paymentFrequency: pav?.egl_Payment_Frequency__c,
                paymentType: pav?.egl_Payment_Type__c,
                paymentDeposit: pav?.egl_down_payment__c || 0,

                ...(!isEmpty(mainLine.Location) && {
                    paymentTool: {
                        billingPreferenceCode: mainLine.Location?.egl_code,
                        id: mainLine.Location?.Id,
                        iban: mainLine.Location?.egl_billingaccount_iban,
                        ...((mainLine.Location.egl_billingaccount_firstname ||
                            mainLine.Location.egl_billingaccount_lastname ||
                            mainLine.Location.egl_billingaccount_fiscalcode) && {
                            holder: {
                                ...(mainLine.Location.egl_billingaccount_firstname && {
                                    firstName: mainLine.Location.egl_billingaccount_firstname,
                                }),
                                ...(mainLine.Location.egl_billingaccount_lastname && {
                                    lastName: mainLine.Location.egl_billingaccount_lastname,
                                }),
                                ...(mainLine.Location.egl_billingaccount_fiscalcode && {
                                    fiscalCode: mainLine.Location.egl_billingaccount_fiscalcode,
                                }),
                            },
                        }),
                        ...((mainLine.Location.egl_subscriber_sepa_firstname ||
                            mainLine.Location.egl_subscriber_sepa_lastname ||
                            mainLine.Location.egl_subscriber_sepa_fiscalcode) && {
                            sepaSubscriber: {
                                ...(mainLine.Location.egl_subscriber_sepa_firstname && {
                                    firstName: mainLine.Location.egl_subscriber_sepa_firstname,
                                }),
                                ...(mainLine.Location.egl_subscriber_sepa_lastname && {
                                    lastName: mainLine.Location.egl_subscriber_sepa_lastname,
                                }),
                                ...(mainLine.Location.egl_subscriber_sepa_fiscalcode && {
                                    fiscalCode: mainLine.Location.egl_subscriber_sepa_fiscalcode,
                                }),
                            },
                        }),
                    },
                }),
            },
            prices: {
                notify: flagToBoolOrNull(pav?.egl_contact_customer__c),
                predetermined: flagToBoolOrNull(pav?.egl_predeterminate_estimate__c),
                ...((containsProductSmartHome([product.ProductType]) ||
                    containsProductMaintenance(product.ProductType) ||
                    containsProductComplex(product.ProductType)) &&
                !_.isEmpty(item.Options)
                    ? calculateNdsPrices(item)
                    : { netPrice: (item.MainLine as unknown as CartItem).PriceListItem?.ListPrice?.toString() }),
            },
            isVisible: isVisibleProduct(item),
            effectiveDate: pav?.egl_customer_request_date__c,
            ...(startDate?.isValid() && { startDate: startDate.toDate() }),
            deliveryAddress: eglAddress2Address(mainLine?.egl_service_address),
            summaryPrice: product?.egl_ProductFooter_line1,
            summaryPriceDiscounted: product?.egl_ProductFooter_line2,
        };
        console.log('[STATE] eglCartLineItem2Product', productState);
        return productState;
    } catch (err) {
        console.error('🎅 eglCartLineItem2Product mapping error', err);
    }
};

export const aptProductType2AptCommodityType = (productType: AptProductType, podPdr?: string): AptCommodityType => {
    return containsProductGas([productType]) || Regex.PDR.test(podPdr)
        ? AptCommodityType.Gas
        : containsProductPower([productType]) || Regex.POD.test(podPdr)
          ? AptCommodityType.Power
          : null;
};

export const d365Asset2Product = ({
    egl_address,
    egl_toponym,
    egl_billingpreference,
    egl_asset,
}: D365Asset): DeepPartial<Product> => ({
    assetId: egl_asset.egl_cpqid || egl_asset.egl_assetid,
    productId: egl_asset.egl_productcode,
    name: egl_asset.egl_productname,
    productType: egl_asset.egl_category,
    podPdr: egl_asset.egl_podpdr,
    pdf: egl_asset.egl_pdf,
    powerOrGas: Regex.POD.test(egl_asset.egl_podpdr) ? AptCommodityType.Power : AptCommodityType.Gas,
    addressType: egl_address?.egl_addresstype,
    family: egl_asset.egl_podpdr ? AptProductFamily.Commodity : null,
    deliveryAddress: {
        d365Id: egl_address?.egl_code,
        toponym: egl_toponym?.egl_name,
        street: egl_address?.egl_street,
        ...getCivicAndSuffix(egl_address?.egl_streetnumber),
        municipality: egl_address?.egl_city,
        province: egl_address?.egl_province,
        country: egl_address?.egl_country,
        cap: egl_address?.egl_zipcode,
        istatCodeProv: istatCodeNormalizer(egl_address?.egl_istatcode, 6).substring(0, 3),
        istatCodeMunicipality: istatCodeNormalizer(egl_address?.egl_istatcode, 6).substring(3),
    },
    paymentInfo: {
        paymentTool: {
            billingPreferenceCode: egl_billingpreference.egl_code,
        },
    } as PaymentInfo,
    technicalDetails: {
        ...(!Regex.POD.test(egl_asset.egl_podpdr)
            ? {
                  gasMeterAdjustmentSerialNumber: egl_asset.egl_pdr_serialnumber,
              }
            : Regex.POD.test(egl_asset.egl_podpdr)
              ? {
                    pwrInstantaneousPower: getNumberOrNull(egl_asset.egl_allocatedpower),
                    pwrAvailablePower: getNumberOrNull(egl_asset.egl_availablepower),
                }
              : {}),
    },
});

export const eglAssetLineItem2AssetAccountData = (asset: Partial<EglAssetLineItem>): DeepPartial<IAssetAccountData> => {
    const isMicrobusiness =
        convertSegmentAptToD365(asset?.Account?.egl_Customer_Type) === D365CustomerSegment.Microbusiness;
    const splittedName = asset?.Account?.Name?.split(' ');
    const accountName = {
        firstName: splittedName?.shift(),
        lastName: splittedName?.join(' '),
    };
    return cleanObj({
        accountNumber: asset?.Account?.AccountNumber,
        accountid: asset?.Account?.egl_account_id,
        mastership: d365AccountMigratedToStrictMastershipType(asset?.Account?.egl_migration_mastercode),
        segment: convertSegmentAptToD365(asset?.Account?.egl_Customer_Type),
        fiscalCode: asset?.Account.egl_customer_fiscalcode,
        vatCode: asset?.Account?.egl_customer_taxcode,
        prefixTelephone1: asset?.Account?.egl_prefix_telephone1,
        telephone1: asset?.Account?.egl_contact_telephone1,
        prefixTelephone2: asset?.Account?.egl_prefix_telephone2,
        telephone2: asset?.Account?.egl_contact_telephone2,
        email: asset?.Account?.egl_contact_email,
        ...(isMicrobusiness && { companyName: asset?.Account?.Name }),
        ...(!isMicrobusiness && {
            firstName: accountName?.firstName,
            lastName: accountName?.lastName,
        }),
    });
};

export interface EglItemGroup<T> {
    MainLine: T;
    Children: Array<T>;
    PrimaryLines: Array<T>;
    SecondaryLines: Array<T>;
    Options: Array<T>;
    RollupLines: Array<T>;
}

export interface IAssetAccountData extends CompanyIdentity {
    mastership: ExistingMastershipType; // Sistema provenienza dell'account: siebel o D365
    /**
     * @description egl_customercode - Identificativo cliente su D365
     */
    accountNumber?: string;
    /**
     * @description AccountId SalesForce
     */
    accountid: string;
    segment: D365CustomerSegment; // Tipologia del cliente: Residenziale o Microbusiness
    prefixTelephone1: string;
    telephone1: string;
    prefixTelephone2: string;
    telephone2: string;
    email: string;
    mainAddress?: StateAddress;
}

export function anyOrderEntryStateToV2(orderEntry: any): OrderEntryState_v2 | null {
    return orderEntry
        ? isOrderEntryStateV2(orderEntry)
            ? orderEntry
            : orderEntryV1ToV2({
                  ...orderEntry,
                  infoProdotti: orderEntry?.infoProdotti || [],
              })
        : null;
}

export function anyOrderEntryStateToV1(orderEntry: any): OrderEntryState | null {
    return orderEntry ? (isOrderEntryStateV2(orderEntry) ? orderEntryV2ToV1(orderEntry) : orderEntry) : null;
}

const PRODUCT_NAME_TO_TYPE_MAP: {
    [kay in AptCommodityType]: (prods: ProductTypeInput | ProductTypeInput[]) => boolean;
} = {
    [AptCommodityType.Power]: containsProductPower,
    [AptCommodityType.Gas]: containsProductGas,
};

export function aptProductTypeToAptCommodityType(productType: ProductTypeInput): AptCommodityType | null {
    return (Object.entries(PRODUCT_NAME_TO_TYPE_MAP).find(([commodityType, productTypeToCommodityTypeValidator]) =>
        productTypeToCommodityTypeValidator(productType),
    ) || [])[0] as AptCommodityType;
}

const CONTACT_SEGMENT_MAP: { [segment in D365CustomerSegment]?: D365ContactType[] } = {
    [D365CustomerSegment.Residential]: [D365ContactType.Cliente],
    [D365CustomerSegment.Microbusiness]: [D365ContactType.LegaleRappresentante, D365ContactType.Referente],
};

export type CustomerDataToD365Mapper = AnagraficaData & { master: MastershipType };

export function customerDataToD365Account(data: CustomerDataToD365Mapper): D365Account {
    const segment = convertSegmentAptToD365(startCase(data.dOMMSegmento?.toLowerCase()) as AptCustomerType);
    const out: Partial<D365Account> = {
        egl_migration_mastercode: mastershipTypeToD365AccountMigrated(data.master),
        //accountid: data.d365?.accountId,
        egl_customercode: data.csn,
        egl_customeraccountcode: data.csn,
        egl_customersegmentcode: segment,
        egl_portalregistration: data.portalRegistration,
        egl_lastname: data.cognomeContatto,
        egl_firstname: data.nomeContatto,
        name: data.ragioneSociale,
        emailaddress1: data.email || data.mailPrincipale,
        legalForm: {
            egl_code: data.formaGiuridica,
        },
        egl_taxcode: data.cf,
        egl_vatcode: data.piva,
        egl_privacy1: data.privacyPromozionaleEni === PrivacyFlag.SI,
        egl_privacy2: data.privacyAnalisiRicercaMercato === PrivacyFlag.SI,
        egl_privacy3: data.privacyPromozionaleAltreImprese === PrivacyFlag.SI,
        egl_preferredchannel: data.preferredChannel,
        numberofemployees: data.numberofemployees?.toString(),
        preferredcontactmethodcode: data.preferredContactMethodCode,
        revenue: data.revenue,
        telephone1Prefix: data.telephone1CallingCode,
        telephone1: data.telefonoPrimario,
        telephone2Prefix: data.telephone2CallingCode,
        telephone2: data.cellulareCliente,
        telephone3Prefix: data.telephone3CallingCode,
        telephone3: data.telephone3,
        egl_vulnerabilitytype_disabled: data.egl_vulnerabilitytype_disabled,
        egl_vulnerabilitytype_over75: data.egl_vulnerabilitytype_over75,
        egl_vulnerabilitytype_saemapre: data.egl_vulnerabilitytype_saemapre,
        egl_vulnerabilitytype_socialbonus: data.egl_vulnerabilitytype_socialbonus,
        addresses: {
            [data.indirizzo?.ruoloIndirizzo]: {
                egl_istatcode: data.indirizzo?.codiceIstatComune,
                egl_street: data.indirizzo?.via,
                egl_province: data.indirizzo?.province || data.indirizzo?.prov,
                egl_city: data.indirizzo?.comune,
                egl_code: data.indirizzo?.codice,
                egl_country: data.indirizzo?.country,
                egl_zipcode: data.indirizzo?.cap,
                egl_streetnumber: data.indirizzo?.civico,
                toponym: data.indirizzo?.toponomastica,
            },
        },
        contacts: {
            [startCase(data.tipoInterlocutore?.toLocaleLowerCase())]: {
                egl_migration_mastercode: data.contactMigrationMasterCode,
                //egl_contactid: data.d365?.contactId,
                egl_taxcode: data.cfContatto,
                firstname: data.nomeContatto,
                lastname: data.cognomeContatto,
            },
        },
    };

    return <D365Account>cleanObj(out);
}

export function d365AccountContactDataToCustomerContact(
    customer: DeepPartial<D365AccountContactData>,
): Partial<UserState> {
    const [telephone1Normalized, telephone2Normalized, telephone3Normalized] = [
        [customer?.telephone1Prefix, customer?.telephone1],
        [customer?.telephone2Prefix, customer?.telephone2],
        [customer?.telephone3Prefix, customer?.telephone3],
    ].map(([prefix, tel]) => parsePhoneNumber(`${(prefix || '').trim()}${(tel || '').trim()}`));

    return {
        customerMastership: d365AccountMigratedToMastershipType(customer?.egl_migration_mastercode),
        customerInfo:
            customer?.egl_taxcode || customer?.egl_customercode || customer?.egl_vatcode
                ? {
                      CustomerType: CustomerType.Account,
                      AccountId: customer?.accountid,
                      CustomerCode: customer?.egl_customercode,
                      Address: customer?.addresses?.COMUNICAZIONE?.street,
                      City: customer?.addresses?.COMUNICAZIONE?.municipality,
                      Email: null,
                      FirstName: customer?.egl_firstname,
                      LastName: customer?.egl_lastname,
                      Name: customer?.name,
                      PostalCode: customer?.addresses?.COMUNICAZIONE?.cap,
                      Privacy1: customer?.egl_privacy1,
                      Privacy2: customer?.egl_privacy2,
                      Privacy3: customer?.egl_privacy3,
                      Province: customer?.addresses?.COMUNICAZIONE?.shortProvince,
                      StreetNumber: getCompleteCivic(customer?.addresses?.COMUNICAZIONE),
                      Telephone1Prefix: telephone2Normalized?.prefix,
                      Telephone1: telephone2Normalized?.phone,
                      Telephone2Prefix: telephone3Normalized?.prefix,
                      Telephone2: telephone3Normalized?.phone,
                      MobilePrefix: telephone1Normalized?.prefix,
                      Mobilephone: telephone1Normalized?.phone,
                      Toponomastica: customer?.addresses?.COMUNICAZIONE?.toponym,
                      TaxCode: customer?.egl_taxcode,
                      PreferredContactMethod: customer?.preferredcontactmethodcode,
                      CustomerAccountCode: customer?.egl_customeraccountcode,
                      PrimaryContactId: customer?.contact?.egl_contactid,
                      CustomerSegment: customer?.egl_customersegmentcode,
                      VatCode: customer?.egl_vatcode,
                      EglMigration: customer?.egl_migration_mastercode,
                      NumberOfEmployees: customer?.numberofemployees as NumberOfEmployees,
                      Revenue: customer?.revenue as Revenue,
                      vulnerabilitytypeDisabled: customer?.egl_vulnerabilitytype_disabled,
                      vulnerabilitytypeOver75: customer?.egl_vulnerabilitytype_over75,
                      vulnerabilitytypeSaemapre: customer?.egl_vulnerabilitytype_saemapre,
                      vulnerabilitytypeSocialBonus: customer?.egl_vulnerabilitytype_socialbonus,
                      ageRange: customer?.ageRange,
                  }
                : undefined,
        contact: customer?.contact
            ? {
                  firstname: customer?.contact?.firstname,
                  lastname: customer?.contact?.lastname,
                  egl_taxcode: customer?.contact?.egl_taxcode,
                  isCustomer: customer?.contact?.egl_taxcode === customer?.egl_taxcode,
                  EglMigration: customer?.contact?.egl_migration_mastercode,
                  contactid: customer?.contact?.egl_contactid,
                  contactRole: customer?.contact?.contactRole,
                  prefixMobilephone: telephone1Normalized?.prefix, // da tenere solo su customerInfo dopo adeguamento componenti formAnag, datiCont.
                  mobilephone: telephone1Normalized?.phone,
                  prefixTelephone2: telephone2Normalized?.prefix,
                  telephone2: telephone2Normalized?.phone,
                  emailaddress1: customer?.emailaddress1, // da tenere solo su customerInfo dopo adeguamento componenti formAnag, datiCont.
                  isValidated: customer?.contact?.egl_migration_mastercode === D365AccountMigrated.D365,
              }
            : undefined,
        cartSegment: customer?.egl_customersegmentcode,
    };
}

export function checkValuesInEnum<K extends string>(srcEnum: { [key: string]: K }, values: string | string[]): K[] {
    if (!values || (Array.isArray(values) && !values?.length)) {
        return null;
    }
    return Object.values(srcEnum).filter((srcEnumValue) =>
        []
            .concat(values || [])
            .some((value) => (srcEnumValue || '').toLowerCase().trim() === (value || '').toLowerCase().trim()),
    );
}

const DESTINATION_USE_MAP: { [key in SupplyUse | DestinationUse]: DestinationUse } = {
    // Destinazioni d'uso apttus
    [SupplyUse.DomesticoResidenziale]: DestinationUse.Home,
    [SupplyUse.DomesticoNonResidenziale]: DestinationUse.Home,
    [SupplyUse.ColonnineDiRicaricaElettrica]: DestinationUse.Appurtenances,
    [SupplyUse.IlluminazionePubblica]: DestinationUse.Appurtenances,
    [SupplyUse.AltriUsi]: DestinationUse.Appurtenances,
    // Destinazioni d'uso già normalizzate
    [DestinationUse.Home]: DestinationUse.Home,
    [DestinationUse.Appurtenances]: DestinationUse.Appurtenances,
};

const SUPPLY_USE_PREVENTIVO_MAP: { [key in SupplyUse]: SupplyUsePreventivo } = {
    // Destinazioni d'uso apttus
    [SupplyUse.DomesticoResidenziale]: SupplyUsePreventivo.DomesticoResidenziale,
    [SupplyUse.DomesticoNonResidenziale]: SupplyUsePreventivo.DomesticoNonResidenziale,
    [SupplyUse.ColonnineDiRicaricaElettrica]: SupplyUsePreventivo.AltriUsi,
    [SupplyUse.IlluminazionePubblica]: SupplyUsePreventivo.IlluminazionePubblica,
    [SupplyUse.AltriUsi]: SupplyUsePreventivo.AltriUsi,
};

export function getValidSupplyUse(supplyUse: string): SupplyUse {
    return (checkValuesInEnum(SupplyUse, supplyUse) || [])[0];
}

export function getValidPreventivoSupplyUse(supplyUse: SupplyUse): SupplyUsePreventivo {
    return (Object.entries(SUPPLY_USE_PREVENTIVO_MAP).find(([key]) => key === supplyUse) || [])[1] || null;
}

export function getValidDestinationUse(srcDestinationUse: string): DestinationUse {
    const srcDestinationUseLowerCase = (srcDestinationUse || '').toLowerCase().trim();
    return (
        (Object.entries(DESTINATION_USE_MAP).find(([key]) => key.toLowerCase() === srcDestinationUseLowerCase) ||
            [])[1] || null
    );
}

export function getValidTypeOfUse(typeOfUse: string | string[], powerOrGas: AptCommodityType.Gas): GasTypeOfUse;
export function getValidTypeOfUse(typeOfUse: string | string[], powerOrGas: AptCommodityType.Power): PowerTypeOfUse;
export function getValidTypeOfUse(typeOfUse: string | string[], powerOrGas?: AptCommodityType): TypeOfUse;
export function getValidTypeOfUse(typeOfUse: string | string[], powerOrGas?: AptCommodityType): TypeOfUse {
    const typeOfUsageArr: string[] =
        typeof typeOfUse === 'string'
            ? (typeOfUse as string).split('|')
            : Array.isArray(typeOfUse)
              ? (typeOfUse as string[])
              : null;
    // Tipologie d'uso specifiche per il GAS
    if (!powerOrGas || powerOrGas === AptCommodityType.Gas) {
        // Tipologie d'uso specifiche per il segmento residenziale
        const residentialGasTypeOfUse = checkValuesInEnum(GasConsumptionUse, typeOfUsageArr);
        if (residentialGasTypeOfUse?.length) {
            return residentialGasTypeOfUse;
        }
        // Tipologie d'uso specifiche per il segmento Microbusiness
        const mbGasTypeOfUse = checkValuesInEnum(GasConsumptionUseMB, typeOfUsageArr);
        if (mbGasTypeOfUse?.length) {
            return mbGasTypeOfUse[0];
        }
    }
    // Tipologie d'uso specifiche per il Power
    if (!powerOrGas || powerOrGas === AptCommodityType.Power) {
        // Tipologie d'uso generiche per residenziale e MB
        const powerTypeOfUse = checkValuesInEnum(PowerConsumptionUse, typeOfUsageArr);
        if (powerTypeOfUse?.length) {
            return powerTypeOfUse[0];
        }
    }

    // Nessun valore e/o valore/i non ammissibili
    return null;
}

export function getAccountPrimaryContact(
    account: Pick<D365Account, 'contacts' | 'egl_customersegmentcode'>,
): D365Account['contacts'][keyof D365Account['contacts']] & { contactRole: keyof D365Account['contacts'] } {
    const contactRole: keyof D365Account['contacts'] = account?.contacts
        ? ((CONTACT_SEGMENT_MAP[account?.egl_customersegmentcode] || []).find(
              (segmentContactRole) => account?.contacts[segmentContactRole],
          ) as D365ContactType)
        : null;
    return account?.contacts[contactRole] ? { ...account?.contacts[contactRole], contactRole } : null;
}

export function d365ContactTypeToAptContactRole(contactRole: D365ContactType): AptContactRole {
    const ADDRESS_TYPE_MAP: { [key in D365ContactType]?: AptContactRole } = {
        [D365ContactType.Cliente]: AptContactRole.Cliente,
        [D365ContactType.LegaleRappresentante]: AptContactRole.RappresentanteLegale,
        [D365ContactType.Referente]: AptContactRole.Referente,
    };
    return ADDRESS_TYPE_MAP[contactRole];
}

export function d365AccountContactDataToDatiAnagraficiMBV2(
    account: DeepPartial<D365AccountContactData>,
): DatiAnagraficiMBV2 {
    return {
        piva: account?.egl_vatcode,
        cf: account?.egl_taxcode,
        companyName: account?.name,
        cfLegal: account?.contact?.egl_taxcode,
        nameLegal: account?.contact?.firstname,
        surnameLegal: account?.contact?.lastname,
        representativeType: d365ContactTypeToAptContactRole(account?.contact?.contactRole),
        legalForm: account?.legalForm?.egl_code,
        isAtoka: undefined,
    };
}

export function fornituraEsistenteToProduct(
    asset: FornituraEsistente,
): Pick<
    Product,
    | 'assetIntegrationId'
    | 'powerOrGas'
    | 'podPdr'
    | 'pdf'
    | 'deliveryAddress'
    | 'addressType'
    | 'isWinBack'
    | 'plannedSwitchOutDate'
    | 'startDate'
> & { businessDetails: Pick<Product['businessDetails'], 'ateco'> } {
    return asset
        ? {
              assetIntegrationId: asset?.assetIntegrationId,
              powerOrGas: asset?.powerOrGas,
              podPdr: asset?.podPdr,
              pdf: asset?.pdf,
              deliveryAddress: fornituraEsistente2Address(asset),
              addressType: AptAddressType.Fornitura,
              isWinBack: !!asset?.isWinBack,
              plannedSwitchOutDate: parseDate(asset?.dataPresuntoSwitchOut),
              startDate: parseDate(asset?.datiInizio),
              businessDetails: {
                  ateco: asset?.atecoCode,
              },
          }
        : null;
}
export function getProductConfigByProductGroups(productExtended: EglProductExtended): Partial<Product> {
    const PG_PRODUCT_CONFIG_MAP: { [key in AptProductGroups]?: Partial<Product> } = {
        [AptProductGroups.Appliance]: { subProductType: SubProductType.Appliance },
        [AptProductGroups.Implant]: { subProductType: SubProductType.Implant },
        [AptProductGroups.RcDanni]: { subProductType: SubProductType.RCDanni },
        [AptProductGroups.CombinedSale]: { isCombinedSale: true },
        [AptProductGroups.Vulnerable]: { vulnerable: true },
    };
    const pgProductConfig = productExtended?.ProductGroups.map((productGroup) => productGroup?.ProductGroup?.Name);
    // Questo mi serve per gestire il fallback sulla logica che controlla il nome polizza fino a che non verranno aggiunti i ProductGroups
    const partialProduct: Partial<Product> = {
        name: productExtended?.Name,
        productType: <AptProductType>productExtended?.ProductType,
    };

    switch (true) {
        case isProductRcDanni(partialProduct):
            pgProductConfig.push('ASS_RC_DANNI');
            break;
        case isImplantInsurance(partialProduct):
            pgProductConfig.push('ASS_IMPIANTI');
            break;
        case isApplianceInsurance(partialProduct):
            pgProductConfig.push('ASS_ELETTRODOMESTICI');
            break;
    }

    return pgProductConfig
        .map((pg) => PG_PRODUCT_CONFIG_MAP[pg])
        .filter(Boolean)
        .reduce((aggr, curr) => ({ ...aggr, ...curr }), <Partial<Product>>{ productId: productExtended?.Id });
}
