import { D365ChannelCode } from '../../enums/d365/d365-channel-code';
import { D365YesNo } from '../../enums/d365/d365-yes-no';
import { D365CustomerSegment } from '../../enums/d365/d365-customer-segment';
import { D365AgentType } from '../../enums/d365/d365-agent-type';
import { D365ProfileCode } from '../../enums/d365/d365-profile-code';
import { D365AgentProfileType } from '../../enums/d365/d365-agent-profile-type';
import { SiNoType } from '../app/si-no-type';
import { D365ObfuscateType } from '../../enums/d365/d365-obfuscate-type';
import { Expose } from 'class-transformer';

export class Agency {
    AgencyId: string;
    Code: string;
    Name: string;
}

export class Agent {
    AgencyId: string;
    AgentId: string;
    AgentType: D365AgentType;
    Code: string;
    Current: string;
    Name: string;
}

export class DefaultAgencyBranch {
    AgencyBranchId: string;
    City: string;
    Code: string;
    Email: string;
    IsStore: boolean;
    Name: string;
    Pec: string;
    Province: string;
    ResponsibleFirstName: string;
    ResponsibleLastName: string;
    ResponsibleRuiEnrollmentDate: Date;
    ResponsibleRuiEnrollmentNumber: string;
    Street: string;
    Telephone: string;
    VirtualAgencyId?: any;
    ZipCode: string;
    AgencyBranchAddress?: string;
}

export class Channel {
    CanAutoAssignLead: boolean;
    CanOpenCatalogFromAccount: boolean;
    CanOpenCatalogFromLead: boolean;
    CanOpenCatalogFromNavigation: boolean;
    CanSearchByPodPdrAndMeterNumber: boolean;
    ChannelId: string;
    Code: D365ChannelCode;
    Name: string;
    UploadDocument: boolean;
    CanSkipPodPdr: boolean;
    CanSkipUploadIDDocuments: boolean;
    CanSkipExecution: boolean;
    CanSelectAgencyBranches: boolean;
    CanSelectAgentCode: boolean;
    CanSetIsInStore: boolean;
    CanDoTransferWithChangeOfSupplier: boolean;
}

export class VirtualAgency {
    Channel: Channel;
    ChannelId: string;
    Code: string;
    EndDate?: any;
    Name: string;
    RegisteredOfficeAddress: string;
    RegisteredOfficeEmail: string;
    RegisteredOfficePec: string;
    RegisteredOfficeTelephone: string;
    RuiEnrollmentDate: Date;
    RuiEnrollmentNumber: string;
    StartDate: Date;
    VirtualAgencyId: string;
    AgencyBranchList: AgencyBranchForMonitoring[]; //Per us #32829 utliizzato solo per monitoring
}

export class VirtualAgent {
    IsCurrentSelected: boolean;
    AgentCode: string;
    BusinessRelationshipEndDate: Date;
    BusinessRelationshipStartDate: Date;
    CurrentCode?: any;
    DefaultAgencyBranch: DefaultAgencyBranch;
    RuiEnllormentDate: Date;
    RuiEnlorrmentNumber: string;
    VirtualAgency: VirtualAgency;
    VirtualAgencyId: string;
    VirtualAgentId: string;
    Skills: Skill[];
    Mandates: Mandates[];
    ResponsibleSkills?: Skill[];
    IsInStore: D365YesNo;
    CurrentAgencyBranch?: AgencyBranchForMonitoring; //Per us #32829 utliizzato solo per monitoring
}

export class AgentInfo {
    Agency: Agency;
    Agent: Agent;
    Blacklisted: boolean;
    Response: string;
    SystemUserId: string;
    VirtualAgents: VirtualAgent[];
    UserConfiguration: UserConfiguration;
    Type: D365AgentProfileType;
    ProfileCode: D365ProfileCode;
    DomainName: string;
    MassiveActionTypeList: MassiveActionType[];
    // START - Agent Authorizations Flags.
    // NOTE: use this flags ONLY IN -->  agent-authorization.service.ts
    CanCreateLeadFromThankYouPage: boolean;
    CanCreateLeadFromHomePage: boolean;
    CanSell: boolean;
    CanAddRejectDeleteReasons: boolean;
    CanSkipPlicoUpload: boolean;
    CanSwitchInVip: boolean;
    CanRecoverSwitchIn: boolean;
    CanDoRecoverActivation: boolean;
    CanDeliberateSwitchIn: boolean;
    CanTransferWithSwitchVip: boolean;
    CanTransferWithSwitchRecovery: boolean;
    CanSearchByPodPdrAndMeterNumber: boolean;
    CanDoVIPActivation: boolean;
    CanDoManualProvisioningActivation: boolean;
    CanViewAllMassiveActionsRequest: boolean;
    CanViewUserOfQuoteOrderTracking: boolean;
    CanDoAdministrativeModifyAssetType: boolean;
    ShouldObfuscateDataInBackOffice: boolean;
    ObfuscateDetailDataInBackOffice: D365ObfuscateType;
    // END - Agent Authorizations Flags.
    ProfileName: string;
}

export class UserConfiguration {
    LastUsedCustomerSegment: D365CustomerSegment;
}

export class Skill {
    Name: string;
    IsInherited: boolean;
}

export class VirtualAgentBackOffice {
    AgentCode: string;
    AgentName: string;
    CurrentCode?: any;
    VirtualAgentId: string;
    RealAgentCode: string;
    VirtualAgencyId: string;
    DefaultAgencyBranch: string;
    DomainName: string;
    AgentTypeCode: D365AgentProfileType;
    IsInStore?: SiNoType;
}

export class Mandates {
    Name: string;
}

export class AgencyBranchForMonitoring {
    //Per us #32829 utliizzato solo per monitoring
    AgencyBranchId: string;
    Name: string;
}

export class VirtualAgentsForBoList {
    VirtualAgents: VirtualAgentBackOffice[];
    AgencyBranches: AgencyBranchForPaper[];
}

export class AgencyBranchForPaper {
    //Per us #32829 utliizzato solo per monitoring
    AgencyBranchId: string;
    Name: string;
    VirtualAgencyId: string;
}

export class MassiveActionType {
    Code: string;
    Type: string;
}
