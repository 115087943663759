import {
    ACondition,
    AFilter,
    AObject,
    AObjectService,
    ApiService,
    CacheService,
    ConfigurationService,
    MetadataService,
    PlatformService,
} from '@congacommerce/core';
import { Injectable, Injector, NgZone } from '@angular/core';
import { BehaviorSubject, combineLatest, from, Observable, of } from 'rxjs';
import { EglCartLight } from '../../../../models/apttus/tables/cart/egl-cart-light';
import { catchError, filter, map, mergeMap, switchMap, take, tap, retry, toArray, concatMap } from 'rxjs/operators';
import { CartItem, CartRequest, CartService, LineItemService, StorefrontService } from '@congacommerce/ecommerce';
import { AptSalesProcess } from '../../../../enums/apttus/apt-sales-process';
import { AppInsightsService } from '../../../shared/app-insights.service';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { LoggerService } from '../../../shared/logger.service';
import { AptPricingStatus } from '../../../../enums/apttus/apt-pricing-status';
import { AptCustomerType } from '../../../../enums/apttus/apt-customer-type';
import { EglCartExtended } from '../../../../models/apttus/tables/cart/egl-cart-extended';
import { EglCartItemExtended } from '../../../../models/apttus/tables/cart/egl-cart-item-extended';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { plainToClass } from 'class-transformer';

@Injectable({ providedIn: 'root' })
class EglTurboService extends ApiService {
    constructor(
        configSrv: ConfigurationService,
        httpClient: HttpClient,
        sanitizer: DomSanitizer,
        private storeFrontSrv: StorefrontService
    ) {
        super(configSrv, httpClient, sanitizer);
    }
    getEndpoint(location: string) {
        return this.storeFrontSrv.getTurboEnvironment().pipe(
            take(1),
            map((url) => url + location)
        );
    }
}

@Injectable({
    providedIn: 'root',
    deps: [EglTurboService],
})
export class EglCartLightService extends AObjectService {
    aObject: any;
    type = EglCartLight;
    private _state: BehaviorSubject<EglCartLight>;

    constructor(
        cacheSrv: CacheService,
        plSrv: PlatformService,
        mtSrv: MetadataService,
        confSrv: ConfigurationService,
        inj: Injector,
        private appInsightsSrv: AppInsightsService,
        private ngZone: NgZone,
        private logger: LoggerService,
        private cartSrv: CartService,
        private turboSrv: EglTurboService,
        private apiSrv: ApiService
    ) {
        super(cacheSrv, plSrv, mtSrv, confSrv, inj);
        this._state = new BehaviorSubject(null);
    }

    globalFilter(): Observable<AFilter[]> {
        return of([
            new AFilter(this.type, [new ACondition(this.type, 'CreatedDate', 'GreaterThan', new Date('2020-05-18'))]),
        ]);
    }

    // Return cached currentCart
    currentCart(): Observable<EglCartLight> {
        return this._state.pipe(filter((c) => !!c));
    }

    refresh() {
        this.getMyCart().subscribe((c) => this.publish(c));
    }

    /**
     * Deprecato in favore di getMyCart del service EglCartService ingettando CartService!!!
     * @deprecated
     */
    getMyCart(): Observable<EglCartLight> {
        const start = new Date().getTime();
        const cartId = CartService.getCurrentCartId() || 'active';

        return of(cartId).pipe(
            mergeMap((cartId) => {
                if (cartId === 'active') {
                    return of(null);
                }
                return this.where([new ACondition(this.type, 'Id', 'Equal', cartId)]);
            }),
            take(1),
            map((carts: EglCartLight[]) => (carts || [])[0] || null),
            tap(async (cart) => {
                this.publish(cart);
            }),
            tap(() => {
                const end = new Date().getTime();
                this.appInsightsSrv.logMetric(`get-my-cart-light: ${cartId}`, (end - start) / 1000);
            })
        );
    }

    /**
     * @param date: data di acquisto del prodotto, in caso di tempovarianza
     */
    updateCurrentCartPricingDate(date: Date): Observable<EglCartExtended> {
        return this.cartSrv.getMyCart().pipe(
            take(1),
            switchMap((cart: EglCartExtended) => {
                cart.LineItems.forEach((li) => {
                    li.PricingStatus = AptPricingStatus.Pending;
                });
                cart.PricingDate = moment(date).toDate();

                //Prima devo aggiornare il cart, poi aggiorno i lineitems
                return this.updateTurboCart(cart).pipe(
                    mergeMap(() => this.update([cart])),
                    mergeMap(([cart]: EglCartExtended[]) =>
                        combineLatest([of(cart), this.updateCartItems(cart.LineItems)])
                    ),
                    map(([cart, lineItems]) => {
                        cart.LineItems = <EglCartItemExtended[]>lineItems;
                        return cart;
                    }),
                    tap((updatedCart) => {
                        this.cartSrv.publish(updatedCart);
                    })
                );
            })
        );
    }

    public updateCartFields(fields: Partial<EglCartLight>): Observable<EglCartLight> {
        return this.getMyCart().pipe(
            take(1),
            mergeMap((cart) =>
                // NON USARE LO STRICT CHECK DI JAVASCRIPT(===)
                !!cart && Object.entries(fields).some(([key, val]) => val != cart[key])
                    ? this.updateAndPublish(Object.assign(cart, fields)).pipe(
                          retry(1),
                          tap(() => {
                              this.cartSrv.refreshCart();
                              this.logger.info(`updated cart ${cart?.Id} on following fields`, fields);
                          })
                      )
                    : of(cart)
            ),
            catchError((err) => {
                this.logger.error(err);
                return of(null);
            })
        );
    }

    /**
     * @description Update cart sales process based on flowtype
     */
    updateCartSalesProcess(salesProcess: AptSalesProcess): Observable<EglCartLight> {
        return this.getMyCart().pipe(
            take(1),
            mergeMap((cart) =>
                salesProcess !== cart.egl_sales_process
                    ? this.updateCartFields({ egl_sales_process: salesProcess })
                    : of(cart)
            )
        );
    }

    /**
     * @description Update cart segment
     */
    updateCartSegment(segment: AptCustomerType): Observable<EglCartLight> {
        return this.updateCartFields({ egl_customer_type: segment });
    }

    updateCartPriceList(PriceListId: string): Observable<EglCartLight> {
        return this.updateCartFields({ egl_commodity_pricelist: PriceListId });
    }

    updateCartTypeOfSale(type: string) {
        return this.updateCartFields({ egl_commodity_typeofsale: type });
    }

    updateAndPublish(cart: EglCartLight): Observable<EglCartLight> {
        return this.update([cart]).pipe(
            take(1),
            map(([updatedCart]) => updatedCart as EglCartLight),
            tap((updatedCart) => {
                this.publish(updatedCart);
            })
        );
    }

    private publish(cart: EglCartLight) {
        this.ngZone.run(() => this._state.next(cloneDeep(cart)));
    }

    deleteCart(cartId: string) {
        const cart = Object.assign(new AObject(), { Id: cartId });
        return this.delete([cart]).pipe(take(1));
    }

    /**
     * Forza l'aggiornamento dell'entità "ProductConfiguration" sull'endpoint del Turbo
     * Attualmente gestisce solo l'aggiornamento della PricingDate
     * @param cart
     * @returns
     */
    private updateTurboCart(cart: EglCartExtended): Observable<void> {
        return this.turboSrv.put(`/pricing/carts/${cart.Id}`, {
            ProductConfiguration: {
                Id: cart.Id,
                Apttus_Config2__PricingDate__c: cart.PricingDate.getTime(),
                Apttus_Config2__PriceListId__c: cart.PriceListId,
                Apttus_Config2__EffectivePriceListId__c: cart.EffectivePriceListId,
            },
        });
    }

    /**
     * Aggiorna il Main LineItem e i suoi Option LineItem collegati
     * @param cartRequest Array di LineItem dove il primo elemento è il Main (Product/Service) e quelli successivi sono le sue Options
     * @returns
     */
    private updateCartItem(cartRequest: CartRequest[]): Observable<EglCartItemExtended[]> {
        const mainLineItemId = (<CartItem>cartRequest[0].LineItem).Id;
        return this.apiSrv
            .put(
                `/carts/${CartService.getCurrentCartId()}/items/${mainLineItemId}?${this.cartSrv[
                    'getQueryParams'
                ]()}&alias=false`,
                cartRequest
            )
            .pipe(map((res) => (res?.LineItems || []).map((li) => plainToClass(EglCartItemExtended, li))));
    }

    private updateCartItems(lineItems: EglCartItemExtended[]): Observable<EglCartItemExtended[]> {
        return of(lineItems).pipe(
            map((lineItemsToUpdate) => LineItemService.groupItems(lineItemsToUpdate)),
            map((groupedLineItems) => {
                return groupedLineItems.map((gli) => {
                    const lineItems = <EglCartItemExtended[]>[gli.MainLine, ...gli.Options];
                    return lineItems.map((li) => ({
                        LineItem: {
                            ...li.toApiJsonFromExpose(),
                        },
                        ProductAttributes: li.AttributeValue || null,
                    }));
                });
            }),
            mergeMap((lineItemsToUpdate) => from(lineItemsToUpdate)),
            concatMap((lineItem) => this.updateCartItem(lineItem)),
            toArray(),
            map((res) => res.reduce((aggr, current) => [...aggr, ...current], []))
        );
    }
}
